import React, { Component } from "react";
import { Container, Header, Segment, Embed, Grid, Image, Divider, Button } from "semantic-ui-react";

import RouteNavButton from "../components/RouteNavButton";
import Text from "../components/Text";
import MetaTags from "../components/MetaTags";
import Polygon from "../components/Polygon";
import MastheadCarousel from "../components/MastheadCarousel";
import config from "../config";

import "./Homepage.css";

export default class Home extends Component {
  render() {
    const meta = {
      title: "Move Forward in Real Estate | Green Ocean",
      description:
        "Move Forward or Get Left Behind. On-Demand videos for today's real estate brokers, teams & agents.",
      url: "/",
    };

    const metaProps = { ...this.props, ...meta };

    return (
      <div>
        <MetaTags {...metaProps} />

        <Segment vertical className="Home-Masthead-background">
          <MastheadCarousel />
          <Polygon points="0,0 57,0 47,100 0,100" />
          <Container className="Home-Masthead-container">
            <Header inverted as="h1" className="Home-Masthead-header">
              Move Forward or
              <br />
              Get Left Behind
            </Header>
            <Header inverted as="h2">
              On-Demand Videos for today's
              <br />
              Real Estate Brokers, Teams
              <br />
              &amp; Agents
            </Header>
            <Button as="a" primary size="huge" href={`${config.app.URL}/signup`}>
              Start Watching
            </Button>
          </Container>
        </Segment>

        <Segment basic className="Home-Segment-Padded Home-Segment-Grey" style={{ marginTop: "0" }}>
          <Container style={{ paddingBottom: "2em" }}>
            <Grid stackable columns={3}>
              <Grid.Row>
                <Grid.Column>
                  <p>
                    <Image src="https://res.cloudinary.com/green-ocean/image/upload/f_auto,q_auto/v1591472424/presenters/jason-00.jpg" />
                  </p>
                  <Text size="medium">Social Media</Text>
                </Grid.Column>
                <Grid.Column>
                  <p>
                    <Image src="https://res.cloudinary.com/green-ocean/image/upload/f_auto,q_auto/v1591472424/presenters/hien-00.jpg" />
                  </p>
                  <Text size="medium">Coaching</Text>
                </Grid.Column>
                <Grid.Column>
                  <p>
                    <Image src="https://res.cloudinary.com/green-ocean/image/upload/f_auto,q_auto/v1591472424/presenters/adam-00.jpg" />
                  </p>
                  <Text size="medium">Leadership</Text>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
          <Container align="center">
            <Text size="huge">GO shows are master classes for a new era of real estate.</Text>
            <Text size="large">
              Real Estate Industry thought leaders, mindset experts and sales professionals
              <br />
              share insights to help you flourish in both business and life.
            </Text>
            <RouteNavButton primary size="huge" href="/shows/">
              See Our Shows
            </RouteNavButton>
          </Container>
        </Segment>

        <Segment basic className="Home-Segment-Padded">
          <Container align="center" style={{ paddingBottom: "2em" }}>
            <Image src="https://res.cloudinary.com/green-ocean/image/upload/f_auto,q_auto/v1591472424/presenters/helen-00.jpg" />
          </Container>

          <Container text align="center">
            <Header as="h2">Learn from the Best</Header>
            <Text size="large">Just starting, running an office or building a team?</Text>
            <Button as="a" primary size="huge" href={`${config.app.URL}/signup`}>
              Start Watching
            </Button>
          </Container>
        </Segment>

        <Segment basic className="Home-Segment-Padded Home-Segment-Grey">
          <Container align="center">
            <Header as="h2">GO Actions&#8482; Are Your Guide</Header>
            <Grid stackable>
              <Grid.Row>
                <Grid.Column verticalAlign="middle" width={6}>
                  <Text size="large">
                    Real estate professionals can get lost in their day-to-day routines.
                  </Text>
                  <Text size="large">Use GO Actions&#8482; and create a rhythm of progress.</Text>
                  <Button as="a" primary size="huge" href={`${config.app.URL}/signup`}>
                    Try For Free
                  </Button>
                </Grid.Column>
                <Grid.Column width={10}>
                  <Image src="https://res.cloudinary.com/green-ocean/image/upload/f_auto,q_auto/v1589759006/web/go-actions.png" />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>

        <Segment basic className="Home-Segment-Padded">
          <Container>
            <Embed id="434467502" source="vimeo" active={true} iframe={{ allowFullScreen: true }} />
          </Container>
        </Segment>

        <Segment basic className="Home-Segment-Padded Home-Segment-Grey">
          <Container>
            <Grid stackable verticalAlign="middle">
              <Grid.Row>
                <Grid.Column width={10}>
                  <Image src="https://res.cloudinary.com/green-ocean/image/upload/f_auto,q_auto/v1592628319/web/howard-hallway.jpg" />
                </Grid.Column>
                <Grid.Column width={6} textAlign="center">
                  <Segment raised className="Home-Model-segment">
                    <Header as="h2">The Green Ocean Approach</Header>
                    <Text size="large">
                      We’re here to fill a need. There is no other platform focused on both
                      empowering real estate agents and equipping leaders.
                    </Text>
                    <Button as="a" primary size="huge" href={`${config.app.URL}/signup`}>
                      Try For Free
                    </Button>
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>

        <Segment basic>
          <Container>
          <Divider horizontal style={{ paddingBottom: "1em" }}>Agents</Divider>
            <Grid stackable celled="internally">
              <Grid.Column width={8} textAlign="center" verticalAlign="middle">
                <Text size="huge">
                  &ldquo;The Green Ocean strategies and actions helped me sell over $200,000 within 12
                  months&rdquo;
              </Text>
                <b>Adam B.</b> - 2 years in real estate
            </Grid.Column>
              <Grid.Column width={8} textAlign="center" verticalAlign="middle">
                <Text size="huge">
                  &ldquo;I love making progress each week. You get both immediate as well as long-term
                  results.&rdquo;
              </Text>
                <b>Jason F.</b> - 15 years in the industry
            </Grid.Column>
            </Grid>
            <Divider horizontal style={{ paddingTop: "2em", paddingBottom: "1em" }}>Managers / Coaches</Divider>
            <Grid stackable celled="internally">
              <Grid.Column width={8} textAlign="center" verticalAlign="middle">
                <Text size="huge">
                  <p>&ldquo;I’ve been using GO for training my new agents and running contests on who does the action items.&rdquo;</p>
                  <p>&ldquo;The short video episodes are perfect for my zoom sales meetings as well.&rdquo;</p>
              </Text>
                <b>Paul B.</b> - Broker / Manager
            </Grid.Column>
              <Grid.Column width={8} textAlign="center" verticalAlign="middle">
                <Text size="huge">
                  <p>&ldquo;As a coach, I love learning the bite-size concepts first then sharing the ideas with other brokers and agents.&rdquo;</p>
                  <p>&ldquo;It definitely showcases my value to those I lead.&rdquo;</p>
              </Text>
                <b>Mona S.</b> - Real Estate Business Coach
            </Grid.Column>
            </Grid>
          </Container>
        </Segment>

        <Segment
          basic
          style={{ marginBottom: "-16px" }}
          className="Home-Segment-Padded Home-Segment-Grey"
        >
          <Container text>
            <Header as="h2">No Lock In</Header>
            <Text size="large">
              Start with a 7-day free trial and you can cancel your subscription at any time.
            </Text>
            <Divider horizontal style={{ paddingTop: "1em", paddingBottom: "1em" }}>
              Access
            </Divider>
            <Header as="h2">Any Browser, Any Time</Header>
            <Text size="large">
              Our shows play in any modern browser on Mac, PC, iOS and Android.
            </Text>
          </Container>
        </Segment>
      </div>
    );
  }
}
